<div class="modal-header border-bottom-0 theme-dark-bg w-100" [class.rtl]="isRtl()">
   <h3 class="fw-600 text-grey-700">{{ modalTitle() }}</h3>
   @if (closeButton()) {
      <ngx-button
         [iconOnly]="true"
         icon="x"
         (clickHandler)="onCloseModal()"
         [style]="darkMode() ? 'dark' : undefined"
      ></ngx-button>
   }
</div>
<div
   id="full-screen-slide"
   class="modal-body theme-dark-bg position-relative"
   [class]="{ rtl: isRtl(), 'audio-player-enabled': srcAudio() }"
>
   @if (slides().length) {
      <app-custom-slider [slides]="slides()" [fullScreen]="true"></app-custom-slider>
   } @else {
      @if (!isHtml()) {
         <p class="pb-1">{{ modalMessage() }}</p>
      }
      @if (isHtml()) {
         <div [innerHTML]="htmlMessage()"></div>
      }
   }
   <div>
      <div class="modal-footer justify-content-end border-0">
         @if (cancelButton()) {
            <ngx-button [label]="cancelButtonText()" (clickHandler)="onCloseModal()" [style]="'light'"></ngx-button>
         }
         @if (confirmButton()) {
            <ngx-button [label]="confirmButtonText()" (clickHandler)="onConfirmModal()" [style]="'dark'"></ngx-button>
         }
      </div>
   </div>
</div>
@if (srcAudio()) {
   <div class="audio-player">
      <app-media-player [mediaSource]="{ url: srcAudio() }" [mediaType]="'audio'"></app-media-player>
   </div>
}
