import {
   AfterViewInit,
   Component,
   computed,
   EventEmitter,
   inject,
   Output,
   SecurityContext,
   signal,
   viewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { MediaPlayerComponent } from '../media-player/media-player.component';
import { CustomSliderComponent } from '../custom-slider/custom-slider.component';
import { NgxButtonComponent } from '../ngx-button/ngx-button.component';

@Component({
   selector: 'app-custom-modal',
   templateUrl: './custom-modal.component.html',
   styleUrls: ['./custom-modal.component.scss'],
   standalone: true,
   imports: [CustomSliderComponent, MediaPlayerComponent, NgxButtonComponent]
})
export class CustomModalComponent implements AfterViewInit {
   posIni!: number;

   closeButton = signal<boolean>(true);
   modalTitle = signal<string>('');
   modalMessage = signal<string | null>('');
   cancelButton = signal<boolean>(true);
   cancelButtonText = signal<string>('');
   confirmButton = signal<boolean>(true);
   confirmButtonText = signal<string>('');
   isHtml = signal<boolean>(false);
   isRtl = signal<boolean>(false);
   slides = signal<any[]>([]);
   srcAudio = signal<string>('');
   darkMode = signal<boolean>(false);

   htmlMessage = computed<string | null>(() =>
      this.isHtml() ? this.dom.sanitize(SecurityContext.HTML, this.modalMessage()) : null
   );

   carousel = viewChild<NgbCarousel>('carousel');

   @Output('confirmed') confirmed: EventEmitter<any> = new EventEmitter<any>();

   private ngbActiveModal = inject(NgbActiveModal);
   private dom = inject(DomSanitizer);

   ngAfterViewInit(): void {
      this.carousel()?.pause();
   }

   move(pos: number) {
      const offset = this.posIni - pos;
      if (offset < -50) this.carousel()?.prev();

      if (offset > 50) this.carousel()?.next();
   }

   onCloseModal() {
      this.ngbActiveModal.close();
   }

   onConfirmModal() {
      this.confirmed.emit();
   }
}
